import React from 'react';

export default function ColorOption({ color, selected, onClick }) {
  const getSelectedClass = () => {
    if (selected) {
      if (color.checkType === 'dark') {
        return 'color-option__circle--selected--dark';
      }

      return 'color-option__circle--selected--light';
    }

    return '';
  };

  return (
    <div className="color-option" onClick={() => onClick(color.id)}>
      <div
        className={`color-option__circle ${getSelectedClass()}`}
        style={{ backgroundColor: color.colorCode }}
      />
    </div>
  );
}
