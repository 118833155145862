import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BestValue } from '../ui/assets/bestValue.svg';
import { ReactComponent as Bronze } from '../ui/assets/bronze.svg';
import { ReactComponent as Chat } from '../ui/assets/chat.svg';
import { ReactComponent as CheckLite } from '../ui/assets/checkedLite.svg';
import { ReactComponent as Gold } from '../ui/assets/gold.svg';
import { ReactComponent as LightBulb } from '../ui/assets/lightbulb.svg';
import { ReactComponent as Pencil } from '../ui/assets/pencil.svg';
import { ReactComponent as Silver } from '../ui/assets/silver.svg';
import Button from './button';
import CollapsibleComponent from './collapsible';
import TooltipComponent from './tooltip';

function MoreIncluded({ text, hoverText, textStrong, textLastPart }) {
  return (
    <div className="package__more-included__item">
      <div className="package__more-included__item__icon">
        <CheckLite />
      </div>
      <div className="package__more-included__item__text">
        <span>{text}</span>
        <span style={{ fontWeight: 'bold' }}>{textStrong}</span>
        <span>{textLastPart}</span>
      </div>
      {hoverText && <TooltipComponent hoverText={hoverText} />}
    </div>
  );
}

export default function PackageOption({
  price,
  oldPrice,
  type,
  caption,
  showBestValueLabel,
  onClick,
  isLarge,
}) {
  const { t } = useTranslation();

  const renderIcon = () => {
    switch (type) {
      case 'BRONZE':
        return <Bronze />;
      case 'SILVER':
        return <Silver />;
      case 'GOLD':
        return <Gold />;
      default:
        return <Bronze />;
    }
  };

  const renderIncluded = (conceptText, revisionText, contactText) => {
    switch (type) {
      case 'BRONZE':
        conceptText = t('pages.packageSelection.packages.bronze.concept');
        revisionText = t('pages.packageSelection.packages.bronze.revision');
        contactText = t('pages.packageSelection.packages.bronze.contact');
        break;
      case 'SILVER':
        conceptText = t('pages.packageSelection.packages.silver.concept');
        revisionText = t('pages.packageSelection.packages.silver.revision');
        contactText = t('pages.packageSelection.packages.silver.contact');
        break;
      case 'GOLD':
        conceptText = t('pages.packageSelection.packages.gold.concept');
        revisionText = t('pages.packageSelection.packages.gold.revision');
        contactText = t('pages.packageSelection.packages.gold.contact');
        break;
      default:
        break;
    }

    return (
      <div className="package__included__items">
        <div>
          <LightBulb />
          {conceptText}
        </div>
        <div>
          <Pencil />
          {revisionText}
        </div>
        <div>
          <Chat />
          {contactText}
        </div>
      </div>
    );
  };

  const renderMoreIncluded = () => {
    let moreIncludedItems = [];
    switch (type) {
      case 'BRONZE':
        moreIncludedItems = [
          {
            text: t('pages.packageSelection.packages.bronze.paint.text'),
            hoverText: t('pages.packageSelection.packages.bronze.paint.hoverText'),
            key: 'bronze',
          },
          {
            text: t('pages.packageSelection.packages.bronze.delivery.text'),
            hoverText: t('pages.packageSelection.packages.bronze.delivery.hoverText'),
            key: 'bronze',
          },
          {
            text: t('pages.packageSelection.packages.bronze.windows.text'),
            hoverText: t('pages.packageSelection.packages.bronze.windows.hoverText'),
            key: 'bronze',
          },
          {
            text: t('pages.packageSelection.packages.bronze.shoppingList.text'),
            key: 'bronze',
          },
          {
            text: t('pages.packageSelection.packages.bronze.floorPlan.text'),
            key: 'bronze',
          },
        ];
        break;
      case 'SILVER':
        moreIncludedItems = [
          {
            text: t('pages.packageSelection.packages.silver.paintFirstPart.text'),
            textStrong: t('pages.packageSelection.packages.silver.paintSecondPart.text'),
            textLastPart: t('pages.packageSelection.packages.silver.paintThirdPart.text'),
            hoverText: t('pages.packageSelection.packages.silver.paintThirdPart.hoverText'),
            key: 'silver',
          },
          {
            textStrong: t('pages.packageSelection.packages.silver.rendering.text'),
            hoverText: t('pages.packageSelection.packages.silver.rendering.hoverText'),
            key: 'silver',
          },
          {
            text: t('pages.packageSelection.packages.silver.delivery.text'),
            hoverText: t('pages.packageSelection.packages.silver.delivery.hoverText'),
            key: 'silver',
          },
          {
            text: t('pages.packageSelection.packages.silver.windows.text'),
            hoverText: t('pages.packageSelection.packages.silver.windows.hoverText'),
            key: 'silver',
          },
          {
            text: t('pages.packageSelection.packages.silver.shoppingList.text'),
            key: 'silver',
          },
          {
            text: t('pages.packageSelection.packages.silver.floorPlan.text'),
            key: 'silver',
          },
        ];
        break;
      case 'GOLD':
        moreIncludedItems = [
          {
            text: t('pages.packageSelection.packages.gold.paintFirstPart.text'),
            textStrong: t('pages.packageSelection.packages.gold.paintSecondPart.text'),
            textLastPart: t('pages.packageSelection.packages.gold.paintThirdPart.text'),
            hoverText: t('pages.packageSelection.packages.gold.paintThirdPart.hoverText'),
            key: 'gold',
          },
          {
            textStrong: t('pages.packageSelection.packages.gold.rendering.text'),
            hoverText: t('pages.packageSelection.packages.gold.rendering.hoverText'),
            key: 'gold',
          },
          {
            text: t('pages.packageSelection.packages.gold.delivery.text'),
            hoverText: t('pages.packageSelection.packages.gold.delivery.hoverText'),
            key: 'gold',
          },
          {
            text: t('pages.packageSelection.packages.gold.windows.text'),
            hoverText: t('pages.packageSelection.packages.gold.windows.hoverText'),
            key: 'gold',
          },
          {
            text: t('pages.packageSelection.packages.gold.shoppingList.text'),
            key: 'gold',
          },
          {
            text: t('pages.packageSelection.packages.gold.floorPlan.text'),
            key: 'gold',
          },
        ];

        break;
      default:
        break;
    }

    return moreIncludedItems.map((item) => {
      return (
        <div className="package__more-included__item" key={item.text + item.key}>
          <MoreIncluded
            text={item.text}
            textStrong={item.textStrong}
            textLastPart={item.textLastPart}
            hoverText={item.hoverText}
          />
        </div>
      );
    });
  };

  return (
    <div className={`package${isLarge ? ' package--large' : ''} fade-in-up`} key={type}>
      {showBestValueLabel && (
        <div className="package__best-value-label">
          <BestValue />
        </div>
      )}
      <div className="package__icon">{renderIcon(type)}</div>
      <div className="package__price">
        <div className="package__price-old-value">{oldPrice}</div>
        <div className="package__price-value">{price}</div>
      </div>
      <div className="package__button">
        <Button
          caption={t('pages.packageSelection.button.select')}
          onClick={onClick}
          buttonType="primary"
          icon="arrowRight"
        />
      </div>
      <div className="package__included-container">
        <div className="package__included">
          <div className="package__included__title">
            {`${t('pages.packageSelection.includedTitleFirstPart')} 
            ${caption} 
            ${t('pages.packageSelection.includedTitleSecondPart')} `}
          </div>
          {renderIncluded()}
        </div>
        <div className="package__more-included">
          <CollapsibleComponent
            title={t('pages.packageSelection.moreIncludedTitle')}
            titleStyle="small"
          >
            <div className="package__more-included__items">{renderMoreIncluded()}</div>
          </CollapsibleComponent>
        </div>
      </div>
    </div>
  );
}
