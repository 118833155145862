import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Rating from './starRating';

const settings = {
  autoplay: true,
  infinite: true,
  speed: 500,
  autoplaySpeed: 3000,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 950,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function reviewContainer() {
  const { t } = useTranslation();
  const reviews = [
    { id: 1, rating: 4.5, text: t('pages.packageSelection.reviewTextManon'), author: 'Manon S.' },
    {
      id: 2,
      rating: 4,
      text: t('pages.packageSelection.reviewTextBastiaan'),
      author: 'Bastiaan L.',
    },
    { id: 3, rating: 4.5, text: t('pages.packageSelection.reviewTextMarthe'), author: 'Marthe J.' },
    {
      id: 4,
      rating: 5,
      text: t('pages.packageSelection.reviewTextEmmanuel'),
      author: 'Emmanuel D.',
    },
  ];

  return (
    <div className="review-container">
      <div>
        <Slider {...settings}>
          {reviews.map((review) => (
            <div key={review.id}>
              <div className="review-item">
                <Rating rating={review.rating} />
                <div>{review.text}</div>
                <p>{review.author}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default reviewContainer;
