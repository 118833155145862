import React from 'react';
import PackageOption from './packageOption';

export default function PackagesContainer({ designPackages, onClick }) {
  const renderOptionComponents = () => {
    return designPackages.map((designPackage) => {
      return (
        <PackageOption
          key={designPackage.type}
          option={designPackage}
          onClick={() => onClick(designPackage)}
          type={designPackage.type}
          caption={designPackage.caption}
          showBestValueLabel={designPackage.type === 'SILVER'}
          price={`€${designPackage.price}`}
          oldPrice={`€${designPackage.oldPrice}`}
          isLarge={designPackage.type === 'SILVER'}
        />
      );
    });
  };

  return (
    <div className="package-options-container block-container">{renderOptionComponents()}</div>
  );
}
