export async function sendProjectSubmittedMessage(token, projectId) {
  const headers = {
    Accept: 'application/json',
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/rest/project/submitMessage?id=${projectId}`,
      {
        method: 'GET',
        headers,
      },
    );

    return response.ok;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function sendProjectContinueMessage(token, projectId) {
  const headers = {
    Accept: 'application/json',
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/rest/project/continueMessage?id=${projectId}`,
      {
        method: 'GET',
        headers,
      },
    );

    return response.ok;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function getDesignPackagePrices(projectId) {
  const headers = {
    Accept: 'application/json',
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/rest/project/packagePrices?id=${projectId}`,
      {
        method: 'GET',
        headers,
      },
    );

    const data = await response.json();
    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export async function saveDesignPackagePrice(projectId, designPackage) {
  const headers = {
    Accept: 'application/json',
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/rest/project/savePackagePrice?id=${projectId}&designPackage=${designPackage}`,
      {
        method: 'GET',
        headers,
      },
    );
    return response.ok;
  } catch (e) {
    console.error(e);
    return null;
  }
}
