import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonContainer from '../../components/buttonContainer';
import PackagesContainer from '../../components/packageOptionContainer';
import { ReactComponent as Discount } from '../../ui/assets/discount.svg';
import ReviewContainer from '../../components/reviewContainer';

export default function OfferSelection({ designPackages, onClickPreviousStep, onClickPackage }) {
  const { t } = useTranslation();

  return (
    <div className="layout-container">
      <div className="block-container">
        <div className="design-package-selection">
          <div className="design-package-selection__container">
            <div className="design-package-selection__header">
              <h3>{t('pages.packageSelection.title')}</h3>
              <div className="design-package-selection__header__subtitle">
                <div>{t('pages.packageSelection.subtitleFirstPart')}</div>
                <div className="design-package-selection__header__subtitle--strong">
                  {t('pages.packageSelection.subtitleSecondPart')}
                </div>
              </div>
            </div>
            <div className="design-package-selection__header__icon">
              <Discount />
            </div>
          </div>
          <div className="layout-container">
            <PackagesContainer onClick={onClickPackage} designPackages={designPackages} />
          </div>
          <ReviewContainer />
        </div>
      </div>
      <div className="button-container-wrapper">
        <ButtonContainer hasPreviousStep previousButtonOnClick={onClickPreviousStep} />
      </div>
    </div>
  );
}
