import React from 'react';
import ColorOption from './colorOption';

export default function ColorOptionsContainer({ colors, selectedColors, onClick }) {
  const renderColorOptionComponents = () => {
    return colors.map((color) => {
      return (
        <ColorOption
          key={color.colorCode}
          color={color}
          selected={selectedColors.find(
            (selectedColor) => selectedColor.colorCode === color.colorCode,
          )}
          onClick={() => onClick(color)}
        />
      );
    });
  };

  return (
    <div className="color-options-container block-container">
      <div className="color-options-container__inner-container">
        {renderColorOptionComponents()}
      </div>
    </div>
  );
}
