import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { getRoles } from './api/user';
import LoginPage from './pages/login-page/loginPage';
import ProjectDetails from './pages/project-details/projectDetails';
import ProjectOverviewClient from './pages/project-overview-client/projectOverviewClient';
import ProjectSteps from './pages/project-steps/projectSteps';
import Registration from './pages/registration/registration';

function App() {
  const [wizardStep, setWizardStep] = useState(0);
  const { isAuthenticated, getAccessTokenSilently, isLoading, user } = useAuth0();

  const handleChangeStep = (step) => {
    setWizardStep(step);
  };

  useEffect(() => {
    const getRolesFromBackend = async () => {
      try {
        const token = await getAccessTokenSilently();
        const updatedRoles = await getRoles(token, user.email);
        if (updatedRoles) {
          // updateIsDesiger(updatedRoles.includes('Designer'));
          // updateIsClient(updatedRoles.includes('Client'));
        }
      } catch (e) {
        console.error('Error while fetching roles', e);
      }
    };
    if (isAuthenticated) {
      getRolesFromBackend();
    }
  }, [isAuthenticated, getAccessTokenSilently, user?.email]);

  return (
    <div>
      <div className="app">
        <Router>
          <Routes>
            {!isAuthenticated && !isLoading && !user && (
              <Route path="/login" element={<LoginPage />} />
            )}
            {isAuthenticated && user && (
              <>
                <Route path="/" element={<ProjectOverviewClient />} />
                <Route path="/login" element={<ProjectOverviewClient />} />
                <Route path="/project/:id" element={<ProjectDetails />} />
              </>
            )}
            {!isAuthenticated && (
              <>
                <Route
                  path="/"
                  element={
                    <ProjectSteps wizardStep={wizardStep} changeWizardStep={handleChangeStep} />
                  }
                />
                <Route
                  path="/new-project"
                  element={
                    <ProjectSteps wizardStep={wizardStep} changeWizardStep={handleChangeStep} />
                  }
                />
                <Route
                  path="/new-project/:id"
                  element={
                    <ProjectSteps wizardStep={wizardStep} changeWizardStep={handleChangeStep} />
                  }
                />
                <Route path="/registration/:id" element={<Registration />} />
              </>
            )}
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
