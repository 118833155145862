import React from 'react';
import { useTranslation } from 'react-i18next';
import ColorOptionsContainer from '../../components/colorOptionsContainer';
import ButtonContainer from '../../components/buttonContainer';

export default function ColorSelection({
  selectedColors,
  onClick,
  onClickNextStep,
  onClickPreviousStep,
}) {
  const { t } = useTranslation();

  const colors = [
    { colorCode: '#3C3934', checkType: 'light' },
    { colorCode: '#B2B4B1', checkType: 'light' },
    { colorCode: '#E1E2DC', checkType: 'dark' },
    { colorCode: '#EDE8D5', checkType: 'dark' },
    { colorCode: '#FFFFFF', checkType: 'dark' },
    { colorCode: '#885633', checkType: 'light' },
    { colorCode: '#DCC6AF', checkType: 'light' },
    { colorCode: '#6A1E69', checkType: 'light' },
    { colorCode: '#9D7286', checkType: 'light' },
    { colorCode: '#796B8F', checkType: 'light' },
    { colorCode: '#A1248A', checkType: 'light' },
    { colorCode: '#D4C9D1', checkType: 'light' },
    { colorCode: '#8B1A1C', checkType: 'light' },
    { colorCode: '#DA3833', checkType: 'light' },
    { colorCode: '#D36D61', checkType: 'light' },
    { colorCode: '#CF2F6D', checkType: 'light' },
    { colorCode: '#EFC3BA', checkType: 'light' },
    { colorCode: '#CC968C', checkType: 'light' },
    { colorCode: '#D988A8', checkType: 'light' },
    { colorCode: '#EF8547', checkType: 'light' },
    { colorCode: '#EAC3A6', checkType: 'light' },
    { colorCode: '#F7CD47', checkType: 'light' },
    { colorCode: '#D6A644', checkType: 'light' },
    { colorCode: '#F4E099', checkType: 'dark' },
    { colorCode: '#285D25', checkType: 'light' },
    { colorCode: '#91984B', checkType: 'light' },
    { colorCode: '#B2C9B9', checkType: 'light' },
    { colorCode: '#5DB334', checkType: 'light' },
    { colorCode: '#C5DD4B', checkType: 'light' },
    { colorCode: '#8EAD9B', checkType: 'light' },
    { colorCode: '#1D50A3', checkType: 'light' },
    { colorCode: '#47A2D9', checkType: 'light' },
    { colorCode: '#C2D5E4', checkType: 'light' },
  ];

  return (
    <div className="layout-container">
      <h3>{t('pages.colorSelection.title')}</h3>
      <div className="layout-container">
        <ColorOptionsContainer colors={colors} selectedColors={selectedColors} onClick={onClick} />
      </div>
      <div className="button-container-wrapper">
        <ButtonContainer
          areFieldsValid={selectedColors.length > 0}
          hasPreviousStep
          previousButtonOnClick={onClickPreviousStep}
          firstButtonCaption={t('pages.colorSelection.buttons.dontKnowButton')}
          firstButtonOnClick={onClickNextStep}
          secondButtonCaption={t('buttons.nextButton')}
          secondButtonOnClick={onClickNextStep}
          secondButtonType="primary"
          secondButtonIcon="arrowRight"
        />
      </div>
    </div>
  );
}
