import React from 'react';

import RoomOption from './roomOption';

export default function RoomOptionContainer({ rooms, selectedRooms, onClick }) {
  const renderOptionComponents = () => {
    return rooms.map((room) => {
      return (
        <RoomOption
          key={room.type}
          selected={selectedRooms.find((selectedRoom) => selectedRoom.type === room.type)}
          room={{ code: 1 }}
          onClick={() => onClick(room)}
        >
          <room.icon />
          <div>{room.name}</div>
        </RoomOption>
      );
    });
  };

  return <div className="room-options-container block-container">{renderOptionComponents()}</div>;
}
