import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from './button';

export default function ButtonContainer({
  areFieldsValid,
  hasPreviousStep,
  previousButtonOnClick,
  firstButtonCaption,
  firstButtonOnClick,
  firstButtonType,
  firstButtonIcon,
  secondButtonCaption,
  secondButtonOnClick,
  secondButtonType,
  secondButtonIcon,
  isDisabled,
}) {
  const [isTransparent, setIsTransparent] = useState(true);
  const buttonContainerRef = useRef(null);

  const { t } = useTranslation();

  function isColliding(buttonContainer, targetContainer) {
    const d1Offset = buttonContainer.getBoundingClientRect();
    const d1Height = buttonContainer.offsetHeight;
    const d1Width = buttonContainer.offsetWidth;
    const d1Top = d1Offset.top + d1Height;
    const d1Left = d1Offset.left + d1Width;

    const d2Offset = targetContainer.getBoundingClientRect();
    const d2Height = targetContainer.offsetHeight;
    const d2Width = targetContainer.offsetWidth;
    const d2Top = d2Offset.top + d2Height;
    const d2Left = d2Offset.left + d2Width;

    return !(
      d1Top < d2Offset.top ||
      d1Offset.top > d2Top ||
      d1Left < d2Offset.left ||
      d1Offset.left > d2Left
    );
  }

  useEffect(() => {
    const buttonContainer = buttonContainerRef.current;
    const targetContainer = document.querySelector('.block-container');

    const handleMovement = () => {
      if (buttonContainer && targetContainer && !areFieldsValid) {
        if (isColliding(buttonContainer, targetContainer)) {
          setIsTransparent(true);
        } else {
          setIsTransparent(false);
        }
      } else {
        setIsTransparent(false);
      }
    };
    try {
      window.removeEventListener('scroll', handleMovement);
      window.removeEventListener('resize', handleMovement);
    } catch (e) {
      console.error('Error removing event listener');
    }

    window.addEventListener('scroll', handleMovement, { once: false });
    window.addEventListener('resize', handleMovement, { once: false });

    handleMovement(buttonContainer, targetContainer);

    return () => {
      window.removeEventListener('scroll', handleMovement);
      window.removeEventListener('resize', handleMovement);
    };
  }, [areFieldsValid]);

  return (
    <div
      className={`button-container ${isTransparent ? 'button-container--transparent' : ''}`}
      ref={buttonContainerRef}
    >
      <div className="button-container__inner-container ">
        {hasPreviousStep && (
          <Button
            caption={t('buttons.backButton')}
            onClick={previousButtonOnClick}
            buttonType="link"
            icon="arrowLeft"
            isDisabled={isDisabled}
          />
        )}
        {firstButtonCaption && (
          <Button
            caption={firstButtonCaption}
            onClick={firstButtonOnClick}
            buttonType={firstButtonType}
            icon={firstButtonIcon}
            isDisabled={isDisabled}
          />
        )}
        {secondButtonCaption && (
          <Button
            caption={secondButtonCaption}
            onClick={secondButtonOnClick}
            buttonType={secondButtonType}
            icon={secondButtonIcon}
            isDisabled={isDisabled}
          />
        )}
      </div>
    </div>
  );
}
