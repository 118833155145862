import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonContainer from '../../components/buttonContainer';
import RoomOptionContainer from '../../components/roomOptionContainer';
import getRooms from '../../data/rooms';

export default function RoomSelection({ selectedRooms, onClick, onClickNextStep }) {
  const { t } = useTranslation();

  const rooms = getRooms();
  return (
    <div className="layout-container">
      <h3>
        {`${t('pages.roomSelection.title')}
        ${t('pages.roomSelection.titleSecondLine')} `}
      </h3>
      <div className="layout-container">
        <RoomOptionContainer rooms={rooms} selectedRooms={selectedRooms} onClick={onClick} />
      </div>
      <div className="button-container-wrapper">
        {selectedRooms.length > 0 && (
          <ButtonContainer
            areFieldsValid={selectedRooms.length > 0}
            isDiabled={selectedRooms.length === 0}
            hasPreviousStep={false}
            secondButtonCaption={t('buttons.nextButton')}
            secondButtonOnClick={onClickNextStep}
            secondButtonType="primary"
            secondButtonIcon="arrowRight"
          />
        )}
      </div>
    </div>
  );
}
