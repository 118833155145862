import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getResource } from '../../api/resource';
import ProjectCardContainer from '../../components/projectCardContainer';
import Sidebar from '../../components/sidebar';

export default function ProjectOverviewClient() {
  const [projects, setProjects] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClickDetails = (project) => {
    navigate(`/project/${project._links.self.href.split('/').pop()}`);
  };

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      const projectResource = await getResource(
        token,
        `${process.env.REACT_APP_BACKEND_URL}/projects`,
      );

      const projectsWithRooms = await Promise.all(
        projectResource._embedded.projects.map(async (project) => {
          const room = await getResource(token, project._links.room.href, token);
          return { ...project, room };
        }),
      );
      setProjects(projectsWithRooms);
    })();
  }, [getAccessTokenSilently]);
  return (
    <div className="page-with-sidebar">
      <Sidebar />
      <div className="layout-container">
        <h3>{t('pages.projectOverviewClient.title')}</h3>
        <div className="subtitle">{t('pages.projectOverviewClient.subtitle')}</div>
        <ProjectCardContainer
          projects={projects}
          onClick={handleClickDetails}
          statusLabel={t('pages.projectOverviewClient.labels.status')}
          dateCreatedLabel={t('pages.projectOverviewClient.labels.dateCreated')}
          deadLineLabel={t('pages.projectOverviewClient.labels.deadline')}
        />
      </div>
    </div>
  );
}
