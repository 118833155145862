import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonContainer from '../../components/buttonContainer';
import Checkbox from '../../components/checkbox';
import Input from '../../components/input';
import RoundImage from '../../components/roundImage';
import Mona from '../../ui/assets/mona.webp';
import isEmailValid from './validation/validation';

export default function PersonalDetails({
  onClickNextStep,
  onClickPreviousStep,
  onChangeInput,
  personalDetails,
}) {
  const [showValidation, setShowValidation] = useState(false);
  const { t } = useTranslation();

  const handleClickNextStep = () => {
    if (
      !isEmailValid(personalDetails.email) ||
      !personalDetails.termsAccepted ||
      !personalDetails.firstName ||
      !personalDetails.lastName
    ) {
      setShowValidation(true);
      return;
    }
    onClickNextStep();
  };

  return (
    <div className="layout-container">
      <div className="block-container">
        <h3>{t('pages.personalDetails.title')}</h3>
        <div className="profile-picture">
          <div className="profile-image">
            <RoundImage image={Mona} />
          </div>
          <div className="profile-text">
            <p>{t('pages.personalDetails.monaHelloText')}</p>
          </div>
        </div>
        <div className="input-block">
          <h4>{t('pages.personalDetails.form.name.title')}</h4>
          <div className="input-fields">
            <Input
              key="firstName"
              onChange={onChangeInput}
              label={`${t('pages.personalDetails.form.name.answer.firstName.label')}*`}
              name="firstName"
              value={personalDetails.firstName || ''}
              type="text"
              showValidation={showValidation && !personalDetails.firstName}
              validationMessage={t('validation.required')}
            />
            <Input
              key="lastName"
              onChange={onChangeInput}
              label={`${t('pages.personalDetails.form.name.answer.lastName.label')}*`}
              name="lastName"
              value={personalDetails.lastName || ''}
              type="text"
              showValidation={showValidation && !personalDetails.lastName}
              validationMessage={t('validation.required')}
            />
          </div>
        </div>
        <div className="input-block">
          <h4>{t('pages.personalDetails.form.contact.title')}</h4>
          <div className="input-fields">
            <Input
              key="email"
              onChange={onChangeInput}
              label={`${t('pages.personalDetails.form.contact.answer.email.label')}*`}
              name="email"
              value={personalDetails.email || ''}
              type="text"
              showValidation={showValidation && !isEmailValid(personalDetails.email)}
              validationMessage={t('validation.email')}
            />
            <Input
              key="phone"
              onChange={onChangeInput}
              label={t('pages.personalDetails.form.contact.answer.mobile.label')}
              name="phone"
              value={personalDetails.phone || ''}
              type="text"
            />
          </div>
          <div className="input-block">
            <Checkbox
              label={
                <>
                  {t('pages.personalDetails.form.terms.textFirstPart')}
                  <a href="/terms" target="_blank" rel="noreferrer">
                    {t('pages.personalDetails.form.terms.link')}
                  </a>
                  {`${t('pages.personalDetails.form.terms.textSecondPart')}*`}
                </>
              }
              onChange={onChangeInput}
              name="termsAccepted"
              checked={personalDetails.termsAccepted || false}
              showValidation={showValidation && !personalDetails.termsAccepted}
              validationMessage={t('validation.required')}
            />
          </div>
        </div>
      </div>
      <div className="button-container-wrapper">
        <ButtonContainer
          areFieldsValid={
            personalDetails.email &&
            personalDetails.termsAccepted &&
            personalDetails.firstName &&
            personalDetails.lastName
          }
          hasPreviousStep
          previousButtonOnClick={onClickPreviousStep}
          firstButtonCaption={null}
          firstButtonOnClick={onClickNextStep}
          secondButtonCaption={t('buttons.nextButton')}
          secondButtonOnClick={handleClickNextStep}
          secondButtonType="primary"
          secondButtonIcon="arrowRight"
        />
      </div>
    </div>
  );
}
