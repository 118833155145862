import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonContainer from '../../components/buttonContainer';
import Dropzone from '../../components/dropzone';

export default function CurrentRoom({
  onClickNextStep,
  onClickPreviousStep,
  picturesCurrentRoom,
  onChangePicturesCurrentRoom,
  onClickDelete,
  isButtonDisabled,
}) {
  const { t } = useTranslation();

  return (
    <div className="layout-container">
      <h3>{t('pages.currentRoom.title')}</h3>
      <div className="subtitle">{t('pages.currentRoom.subtitle')}</div>
      <div className="layout-container">
        <Dropzone
          onChange={onChangePicturesCurrentRoom}
          files={picturesCurrentRoom}
          onClickDelete={onClickDelete}
        />
        <div className="button-container-wrapper">
          <ButtonContainer
            areFieldsValid={picturesCurrentRoom.length > 0}
            hasPreviousStep
            previousButtonOnClick={onClickPreviousStep}
            firstButtonCaption={t('pages.currentRoom.buttons.dontKnowButton')}
            firstButtonOnClick={onClickNextStep}
            secondButtonCaption={t('buttons.nextButton')}
            secondButtonOnClick={onClickNextStep}
            secondButtonType="primary"
            secondButtonIcon="arrowRight"
            isDisabled={isButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
}
