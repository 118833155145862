import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getResource, patchResource } from '../../api/resource';
import { createUser } from '../../api/user';
import ButtonContainer from '../../components/buttonContainer';
import Input from '../../components/input';
import { ReactComponent as Logo } from '../../ui/assets/logoLarge.svg';
import { isEmailFieldValid, isPasswordFieldValid } from './validation/validation';

export default function Registration() {
  const [showValidation, setShowValidation] = useState(false);
  const [user, setUser] = useState({
    email: '',
    password: '',
    passwordConfirmation: '',
  });
  const [project, setProject] = useState({});

  const { t } = useTranslation();
  const { id } = useParams();

  const onChangeInput = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const getProject = useCallback(async () => {
    if (id) {
      const projectResource = await getResource(
        null,
        `${process.env.REACT_APP_BACKEND_URL}/projects/${id}`,
      );

      return projectResource;
    }
    return null;
  }, []);

  const getClient = useCallback(async (projectResource) => {
    const clientResource = await getResource(null, projectResource._links.client.href);
    return clientResource;
  }, []);

  const getAppUser = useCallback(async (personId) => {
    const appUserResource = await getResource(
      null,
      `${process.env.REACT_APP_BACKEND_URL}/appUsers/${personId}`,
    );
    return appUserResource;
  }, []);

  useEffect(() => {
    if (id) {
      (async () => {
        const projectResource = await getProject(id);
        if (!projectResource) {
          window.location.replace('/login');
        }
        setProject(projectResource);
      })();
    } else {
      window.location.replace('/login');
    }
  }, [getProject]);

  const handleClickRegister = async () => {
    if (
      !isEmailFieldValid(user.email) ||
      !isPasswordFieldValid(user.password) ||
      !isPasswordFieldValid(user.passwordConfirmation) ||
      user.password !== user.passwordConfirmation
    ) {
      setShowValidation(true);
      return;
    }
    setShowValidation(false);
    const newAppUser = await createUser(user);
    const client = await getClient(project);
    const appUser = await getAppUser(newAppUser.id);
    await patchResource(null, client._links.self.href, {
      appUser: appUser._links.self.href,
    });
    window.location.replace('/login');
  };

  return (
    <div className="layout-container">
      <div className="project-start">
        <Logo />
        <div>
          <h3>{t('pages.registration.title')}</h3>
          <div className="subtitle">{t('pages.registration.subtitle')}</div>
        </div>
        <div className="project-start__input-block">
          <div className="input-block">
            <div className="input-fields">
              <Input
                key="email"
                onChange={onChangeInput}
                label={`${t('pages.registration.email.label')}*`}
                name="email"
                value={user.email || ''}
                type="text"
                showValidation={showValidation && !isEmailFieldValid(user.email)}
                validationMessage={t('validation.email')}
              />
            </div>
            <div className="input-fields">
              <Input
                key="password"
                onChange={onChangeInput}
                label={`${t('pages.registration.password.label')}*`}
                name="password"
                value={user.password || ''}
                type="text"
                showValidation={showValidation && !isPasswordFieldValid(user.password)}
                validationMessage={t('validation.password')}
              />
            </div>
            <div className="input-fields">
              <Input
                key="passwordConfirmation"
                onChange={onChangeInput}
                label={`${t('pages.registration.passwordConfirmation.label')}*`}
                name="passwordConfirmation"
                value={user.passwordConfirmation || ''}
                type="text"
                showValidation={
                  showValidation &&
                  (!isPasswordFieldValid(user.passwordConfirmation) ||
                    user.passwordConfirmation !== user.password)
                }
                validationMessage={
                  user.passwordConfirmation !== user.password
                    ? t('validation.passwordMatch')
                    : t('validation.password')
                }
              />
            </div>
          </div>
          <div className="button-container-wrapper">
            <ButtonContainer
              areFieldsValid={isEmailFieldValid(user.email) && isEmailFieldValid(user.password)}
              firstButtonCaption={t('pages.registration.buttons.login')}
              firstButtonOnClick={() => {
                window.location.replace('/login');
              }}
              firstButtonType="link"
              secondButtonCaption={t('pages.registration.buttons.register')}
              secondButtonOnClick={handleClickRegister}
              secondButtonType="primary"
              secondButtonIcon="arrowRight"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
