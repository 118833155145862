import React from 'react';
import EnumerationOption from './enumerationOption';

export default function EnumerationOptionContainer({ options, selectedOption, onClick, name }) {
  const renderOptionComponents = () => {
    return options.map((option) => {
      return (
        <EnumerationOption
          key={option.id}
          selected={option.id === selectedOption.id}
          option={option}
          onClick={() => onClick(option)}
          name={name}
        />
      );
    });
  };

  return (
    <div className="enumeration-options-container block-container">{renderOptionComponents()}</div>
  );
}
