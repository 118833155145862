import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

function Banner() {
  const { t } = useTranslation();

  return (
    <div className="banner">
      <Trans>{t('banner.caption')}</Trans>
    </div>
  );
}

export default Banner;
