import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloudArrowUp } from '../ui/assets/cloudArrowUp.svg';
import { ReactComponent as DeleteCircle } from '../ui/assets/deleteCircle.svg';
import Button from './button';

export default function Dropzone({ onChange, files, onClickDelete }) {
  const { t } = useTranslation();
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        file.blob = URL.createObjectURL(file);
      });
      onChange(acceptedFiles);
    },
    [onChange],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="dropzone block-container">
      <div {...getRootProps({ className: 'dropzone__dropArea' })}>
        <CloudArrowUp />
        <input {...getInputProps()} />

        <div className="dropzone__dropArea__content">
          <div className="dropzone__dropArea__title">{t('dropZone.title')}</div>
          <div className="dropzone__dropArea__subtitle">{t('dropZone.subtitle')}</div>
          <div className="dropzone__dropArea__or">{t('dropZone.or')}</div>
          <div className="dropzone__dropArea__button">
            <Button caption={t('dropZone.button')} buttonType="default" onClick={() => null} />
          </div>
        </div>
      </div>
      <div className="dropzone__files">
        {files.map((file) => (
          <div key={file.blob} className="dropzone__files__file">
            <div className="dropzone__files__file__deleteIcon" onClick={onClickDelete(file)}>
              <DeleteCircle />
            </div>
            <img
              className="dropzone__files__file__image"
              key={file.blob}
              src={file.blob}
              alt="uploaded"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
