import React from 'react';
import StyleOption from './styleOption';

export default function StyleOptionContainer({ styles, selectedStyles, onClick }) {
  const renderStyleOptionComponents = () => {
    return styles.map((style) => (
      <StyleOption
        key={style.imageId}
        selected={selectedStyles.find((selectedStyle) => selectedStyle.imageId === style.imageId)}
        onClick={() => onClick(style)}
      >
        <img src={style.image} alt="" />
      </StyleOption>
    ));
  };
  return <div className="style-options-container">{renderStyleOptionComponents()}</div>;
}
