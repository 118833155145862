import isEmailValid from '../../../validation/emailValidation';
import isPasswordValid from '../../../validation/passwordValidation';

export function isEmailFieldValid(email) {
  return isEmailValid(email);
}

export function isPasswordFieldValid(password, passwordConfirmation) {
  return isPasswordValid(password, passwordConfirmation);
}
